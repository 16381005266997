<script>
export default {
    name: 'AppSidebarNavLink',

    functional: true,

    render(createElement, { props }) {
        const createRelative = (link) => createElement(
            'router-link',
            {
                class: 'px-2 -mx-2 py-1 transition duration-200 ease-in-out relative block hover:translate-x-2px hover:text-gray-900 text-gray-600',
                props: {
                    'active-class': 'sidebar-link-active',
                },
                attrs: {
                    to: link.path,
                },
            },
            [
                createElement(
                    'span',
                    {
                        class: 'background rounded absolute inset-y-0 left-0 right-2',
                    },
                ),
                createElement(
                    'span',
                    {
                        class: 'text relative',
                    },
                    [link.title],
                ),
            ],
        );

        const createAbsolute = (link) => createElement(
            'a',
            {
                class: 'px-2 -mx-2 py-1 transition duration-200 ease-in-out block hover:translate-x-2px hover:text-gray-900 text-gray-600',
                attrs: {
                    href: link.path,
                    target: '_blank',
                },
            },
            [
                link.title,
            ],
        );

        return /^http(s?).*/.test(props.link.path)
            ? createAbsolute(props.link)
            : createRelative(props.link);
    },
};
</script>
